export const increment = (multiply: number) => {
    return {
        type: 'INCREMENT',
        payload: multiply,
    };
};

export const addData = (data: any) => {
    return {
        type: 'ADD_DATA',
        payload: data,
    }
}

export const addDraftData = (data: any) => {
    return {
        type: 'ADD_DRAFT_DATA',
        payload: data,
    }
}

export const logError = (isError: boolean) => {
    return {
        type: 'LOG_ERROR',
        payload: isError,
    }
}
