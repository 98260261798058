import React from "react";
import './LoadingOverlay.scss'
import {CircularProgress} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

type LoadingOverlayProps = {
    children?: any;
}

const LoadingOverlay = (props: LoadingOverlayProps) => {
    return (
        <div className={"loading-overlay-container"}>
            <CircularProgress />
            <Typography variant={"body1"}>{props.children}</Typography>
        </div>
    )
}

export default LoadingOverlay;