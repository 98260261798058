import React, {useState} from "react";
import './BlogPreview.scss';
import moment from 'moment'
import 'moment/locale/fr.js'
import {Typography} from "@material-ui/core";
import CSS from "csstype";
import BlogPostType from "../../models/BlogPostType";
import {useHistory} from "react-router-dom";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {useTranslation} from "react-i18next";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {withFirebase} from "../Firebase";

type BlogPreviewProps = {
    blogPost: BlogPostType;
    isEndPost?: boolean;
    isDraft?: boolean;
    firebase: any;
    reloadData: any;
}

const BlogPreview = (props: BlogPreviewProps) => {
    let history = useHistory();
    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = useState(null);
    const wrapper = React.createRef();

    const handleClick = (event: any) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event: any, from: number) => {
        event.stopPropagation();
        setAnchorEl(null);
        switch (from) {
            case 0: {
                //Around
                break;
            }
            case 1: {
                //edit
                if(props.isDraft)
                {
                    history.push('/draft/' + props.blogPost.id);
                } else {
                    history.push('/updateBlog/' + props.blogPost.id);
                }
                break;
            }
            case 2: {
                //delete
                let confirmComand: any = window.confirm(t('utils.deletePostConfim'));
                if (confirmComand) {
                    if(props.isDraft) {
                        props.firebase.removeData(process.env.REACT_APP_BLOG_DRAFT_URL! + props.blogPost.id);
                    } else {
                        props.firebase.removeData(process.env.REACT_APP_BLOG_POST_URL! + props.blogPost.id);
                    }

                    props.reloadData();
                }
                break;
            }
            default: {
                console.log('Error');
            }
        }
    };

    moment.locale('fr');

    const onBlogPreviewClick = () => {
        history.push('/blog/' + props.blogPost.id);
    };

    const backgroundHeaderStyle: CSS.Properties = {
        backgroundImage: "url("+ props.blogPost.coverPicture.src + ")"
    }

    return(
        <div className={props.isDraft ? "draft blog-preview-card": "blog-preview-card "} onClick={onBlogPreviewClick}>
            <div className={"cover-image"} style={backgroundHeaderStyle}></div>
            <div className={"preview-text"}>
                <Typography variant={"h4"} className={"title"}>{props.blogPost.title}</Typography>
                <Typography variant={"body2"} className={"timestamp"}>{moment(props.blogPost.timestamp).format("Do MMMM YYYY")}</Typography>
                <Typography variant={"body2"} className={"short-description"}>{props.blogPost.shortDescription}</Typography>
            </div>
            <div className={"edit-button"}>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    <MoreVertIcon />
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={(event) => handleClose(event, 0)}
                    ref={wrapper}
                >
                    <MenuItem onClick={(event) => handleClose(event, 1)}>{t('utils.edit')}</MenuItem>
                    <MenuItem onClick={(event) => handleClose(event, 2)}>{t('utils.delete')}</MenuItem>
                </Menu>
            </div>
        </div>
    )
};

export default withFirebase(BlogPreview);
