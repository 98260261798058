import './CreateBlog.scss';
import React, {useEffect, useState} from "react";
import {AppBar} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";

import TextField from "@material-ui/core/TextField";
import moment from "moment";
import BlogPicture from "../../models/BlogPicture";
import {withFirebase} from "../../components/Firebase";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";
import {findPostWithId} from "../../utils/utils";
import BlogPostType from "../../models/BlogPostType";
import {useSelector} from "react-redux";
import BlogPost from "../BlogPost";

import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import LoadingOverlay from "../../components/LoadingOverlay";


type CreateBlogProps = {
    firebase: any;
};

const CreateBlog = (props: CreateBlogProps) => {

    const { t } = useTranslation();
    let { id } = useParams();
    const [imgList, setImageList] = useState([]);
    const [textContent, setTextContent] = useState("");
    const [coverImage, setCoverImage] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [finalPost, setFinalPost] = useState<BlogPostType>();
    const [showPreview, setShowPreview] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDraft, setIsDraft] = useState(false);

    let history = useHistory();
    let match = useRouteMatch("/draft");
    // @ts-ignore
    const blogList: [BlogPostType] = useSelector(state => state.blogList);
    const [blogPost, setBlog] = useState<BlogPostType>(
        {
            id: "-1",
            title: "",
            shortDescription: "",
            timestamp: Date.now(),
            coverPicture: {
                src: "",
                alt: "",
            },
            content: [],
            location: "",
            pictures: []
        }
    );

    useEffect( () => {
        if (!blogList) {
            history.push('/');
            return;
        }
        if(id !== undefined) {
            setIsUpdate(true);
            const blog = blogList[findPostWithId(blogList, id!)]
            setBlog(blog)
            // @ts-ignore
            setImageList(blog.pictures);
            let string: string = "";
            blog.content?.forEach( (blogContent: any, index: number) => {
                if(index >= blog.content!.length - 1) {
                    string += blogContent.content
                } else {
                    string += blogContent.content + "\n\n";
                }
            });
            setTextContent(string);
        }
        if(match) {
            setIsDraft(match.url === "/draft");
        }
    }, [id, blogList, history, match])





    const toolbar = (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6">
                    {t('createPost.title')}
                </Typography>
            </Toolbar>
        </AppBar>
    );

    const onInputChange = (event: any) => {
        event.persist();
        setBlog(prevState => {
            return {...prevState, [event.target.name]: event.target.value}
        });
    };

    const handleTextChange = (event: any) => {
        event.persist();
        setTextContent(event.target.value);
    };

    const onCoverImageAltChange = (event: any) => {
        event.persist();
        setBlog(prevState => {
            return {...prevState, coverPicture: {
                src: blogPost.coverPicture.src,
                alt: event.target.value
            }}
        });
    }


    const handleFiles = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if(isUpdate) {
            setIsLoading(true);
            Array.from(event.dataTransfer.files).forEach( (file) => {
                console.log(file)
                props.firebase.uploadFileAndGetUrl(file, blogPost.timestamp.toString()).then((result: any) => {
                    // @ts-ignore
                    setFileList(prevState => {
                        return [...prevState, file];
                    });
                    // @ts-ignore
                    setImageList(prevState => {
                        return [...prevState, {src: result, alt: 'Image from blog'}];
                    });
                    setIsLoading(false);
                });
            });
        } else {
            Array.from(event.dataTransfer.files).forEach( (file) => {
                // @ts-ignore
                setFileList(prevState => {
                    return [...prevState, file];
                });
                // @ts-ignore
                setImageList(prevState => {
                    return [...prevState, {src: URL.createObjectURL(file), alt: 'Image from blog'}];
                });
            })
        }
    };

    const handleCoverImage = (event: any) => {
        event.preventDefault();
        event.persist();
        if(isUpdate) {
            setIsLoading(true);
            props.firebase.uploadFileAndGetUrl(event.dataTransfer.files[0], blogPost.timestamp).then((result: any) => {
                setCoverImage(event.dataTransfer.files[0]);
                // @ts-ignore
                setBlog(prevState => {
                    return {...prevState, coverPicture: {src: result, alt: "Cover image"}}
                });
                setIsLoading(false);
            })
        }
        setCoverImage(event.dataTransfer.files[0]);
        // @ts-ignore
        setBlog(prevState => {
            return {...prevState, coverPicture: {src: URL.createObjectURL(event.dataTransfer.files[0]), alt: "Cover image"}}
        });
    }

    const imageDelete = (event: any) => {
        let confirmComand: any = window.confirm(t('utils.deleteConfirm'));
        if (confirmComand) {
            let temp =  imgList.slice();
            temp.splice(event.target.dataset.key, 1);
            setImageList(temp);
            temp = fileList.slice();
            temp.splice(event.target.dataset.key, 1);
            setFileList(temp);
        }
    }

    const submit = (event: any, type: number) => {
        let tempfinalPost = blogPost;
        const date = moment(tempfinalPost.timestamp).format('YYYY/MM/DD/');
        tempfinalPost.timestamp = Number(moment(tempfinalPost.timestamp).format('x'));
        tempfinalPost.pictures = imgList;
        const textContentList = textContent.split("\n\n");
        tempfinalPost.content = [];
        textContentList.forEach( (text: string) => {
            // @ts-ignore
            tempfinalPost.content.push({type: "text", content: text});
        });
        setFinalPost(tempfinalPost);
        switch (type) {
            case 0: {
                //Draft
                setIsLoading(true);
                if(!isUpdate) {
                    console.log('new draft');
                    let coverPromise = new Promise( (resolution) => props.firebase.uploadFileAndGetUrl(coverImage, 'blog/' + date).then((result: any) => {
                        tempfinalPost.coverPicture.src = result;
                        resolution();
                    }));
                    let picturesPromise = new Promise ( (resolution => {
                        let i = 0;
                        fileList.forEach((file, index) => {
                            props.firebase.uploadFileAndGetUrl(file, 'blog/' + date).then((result: any) => {
                                // @ts-ignore
                                tempfinalPost.pictures[index].src = result
                                i++;
                                if(i >= fileList.length) {
                                    resolution();
                                }
                            });
                        });
                    }));
                    Promise.all([coverPromise, picturesPromise]).then(() => {
                        props.firebase.writeData(tempfinalPost, process.env.REACT_APP_BLOG_DRAFT_URL!).then((snapshot: any) => {
                            setIsLoading(false);
                            history.push('/');
                        }).catch((error: any) => {
                            console.log(error);
                        });
                    });
                } else {
                    props.firebase.updateData(tempfinalPost, process.env.REACT_APP_BLOG_DRAFT_URL! + tempfinalPost.id).then((snapshot: any) => {
                        setIsLoading(false);
                        history.push('/');
                    }).catch((error: any) => {
                        console.log(error);
                    });
                }
                break;
            }
            case 1: {
                if(!isUpdate) {
                    setIsLoading(true);
                    let coverPromise = new Promise( (resolution) => props.firebase.uploadFileAndGetUrl(coverImage, 'blog/' + date).then((result: any) => {
                        tempfinalPost.coverPicture.src = result;
                        resolution();
                    }));
                    let picturesPromise = new Promise ( (resolution => {
                        let i = 0;
                        fileList.forEach((file, index) => {
                            props.firebase.uploadFileAndGetUrl(file, 'blog/' + date).then((result: any) => {
                                // @ts-ignore
                                tempfinalPost.pictures[index].src = result
                                i++;
                                if(i >= fileList.length) {
                                    resolution();
                                }
                            });
                        });
                    }));
                    //publish
                    Promise.all([coverPromise, picturesPromise]).then(() => {
                        setIsLoading(false);
                        setShowPreview(true);
                    });
                } else {
                    setShowPreview(true);
                }
                break;
            }
            default: {
                console.log('an error has occur...');
            }
        }
    };

    const backgroundPreviewClick = () => {
        setShowPreview(false);
    };

    const publishPost = () => {
        if(isDraft) {
            publishDraftAsPost()
            return
        }
        if(!isUpdate) {
            setIsLoading(true);
            finalPost!.created_at = Date.now();
            props.firebase.writeData(finalPost, process.env.REACT_APP_BLOG_POST_URL!).then((snapshot: any) => {
                setIsLoading(false);
                history.push('/');
            }).catch((error: any) => {
                console.log(error);
            });
        } else {
            setIsLoading(true);
            finalPost!.updated_at = Date.now();
            props.firebase.updateData(finalPost, process.env.REACT_APP_BLOG_POST_URL! + finalPost!.id).then((snapshot: any) => {
                setIsLoading(false);
                history.push('/');
            }).catch((error: any) => {
                console.log(error);
            });
        }
    }

    const publishDraftAsPost = () => {
        setIsLoading(true);
        props.firebase.removeData(process.env.REACT_APP_BLOG_DRAFT_URL! + finalPost!.id);
        finalPost!.created_at = Date.now();
        props.firebase.writeData(finalPost, process.env.REACT_APP_BLOG_POST_URL!).then((snapshot: any) => {
            setIsLoading(false);
            history.push('/');
        }).catch((error: any) => {
            console.log(error);
        });
    }

    const blogPreview = (
        <div className={"modal-background"} onClick={backgroundPreviewClick}>
            <div className={"blog-post"}>
                <Button variant="contained" color="primary" className={"publish-button"} onClick={publishPost}>{t('utils.publish')}</Button>
                <BlogPost blog={finalPost}/>
            </div>
        </div>
    );

    const allowDrop = (ev: any) => {
        ev.preventDefault();
        ev.stopPropagation();

    }

    const onCoverImageClick = () => {
        document.getElementById('files')!.click();
    };

    const onListImagesClick = () => {
        document.getElementById('filesList')!.click();
    };

    const onChangeCoverImageInput = (event: any) => {
        event.dataTransfer = event.target;
        handleCoverImage(event);
    };

    const onChangeFilesInput = (event: any) => {
        event.dataTransfer = event.target;
        handleFiles(event);
    };

    const editingBlog =(
        <>
            <form className={"blog-content"} noValidate autoComplete="off">
                <fieldset>
                    <TextField required id="standard-required" label={t('utils.title')} value={blogPost.title} onChange={onInputChange} name="title" className={"text-field"} placeholder={t('createPost.titlePlaceholder')} />
                    <input type="file" id="files" name="files" accept="image/png, image/jpeg" onChange={onChangeCoverImageInput} className={"hidden-input"} />
                    <div className={"drag-and-drop cover text-field"} onDrop={handleCoverImage} onDragOver={allowDrop} onClick={onCoverImageClick}>
                        {blogPost.coverPicture.src !== "" ? (
                            <img src={blogPost.coverPicture.src} alt={blogPost.coverPicture.alt} width={"100%"} />
                        ): <>
                            <InsertPhotoIcon className={"icon"}/>
                            <Typography variant={"subtitle1"}>{t('createPost.addCoverImage')}</Typography>
                        </>}
                    </div>
                    {blogPost.coverPicture.src !== "" ?
                        <TextField label={t('utils.legend')} value={blogPost.coverPicture.alt} onChange={onCoverImageAltChange} name="cover-image" className={"text-field"} placeholder={t('createPost.addImageLabel')} /> : null
                    }
                    <TextField
                        id="date"
                        type="date"
                        name="timestamp"
                        required
                        label={t('utils.date')}
                        value={moment(blogPost.timestamp).format('YYYY-MM-DD')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={onInputChange}
                        className={"text-field"}
                    />
                    <TextField label={t('utils.location')} value={blogPost.location} onChange={onInputChange} name="location" className={"text-field"} placeholder={t('createPost.locationPlaceholder')} />
                    <TextField label={t('utils.shortDescription')} multiline value={blogPost.shortDescription} onChange={onInputChange} name="shortDescription" className={"text-field"} placeholder={t('createPost.shortDescriptionPlaceholder')} />
                </fieldset>
                <fieldset>
                    <TextField
                        id="outlined-multiline-static"
                        label={t('utils.content')}
                        multiline
                        value={textContent}
                        variant="outlined"
                        onChange={handleTextChange}
                    />
                </fieldset>
                <div className={"images-container"} id={"images-container"}>
                    {
                        imgList.map (( img: BlogPicture, index) =>  <img src={img.src} alt={img.alt} key={index} data-key={index} onClick={imageDelete} onDrop={handleFiles} onDragOver={allowDrop} />)
                    }
                </div>
                <fieldset>
                    <div className={"drag-and-drop imagesList text-field"} onDrop={handleFiles} onDragOver={allowDrop} onClick={onListImagesClick}>
                        <PhotoLibraryIcon className={"icon"}/>
                        <Typography variant={"subtitle1"}>{t('createPost.addImages')}</Typography>
                    </div>
                    <input className={"hidden-input"} type="file" id="filesList" name="files" accept="image/png, image/jpeg" multiple onChange={onChangeFilesInput} />
                </fieldset>
            </form>
            <div className={"buttons-container"}>
                <Button className={"button"} onClick={() => history.push('/')}>{t('utils.cancel')}</Button>
                <Button className={"button"} onClick={(event) => submit(event, 0)}>{t('utils.draft')}</Button>
                <Button className={"button"} variant="contained" color="primary" onClick={(event) => submit(event, 1)}>{t('utils.preview')}</Button>
            </div>
        </>

    )

    return (
        <div className={"create-blog-container"}>
            {isLoading ? <LoadingOverlay>{t('utils.loadingImages')}</LoadingOverlay>: null}
            {toolbar}
            {editingBlog}
            {finalPost && showPreview? blogPreview : null}
        </div>
    )

};

export default withFirebase(CreateBlog);
