import blogListReducer from "./blogListReducer";

import {combineReducers} from "redux";
import networkErrorReducer from "./networkErrorReducer";
import blogDraftListReducer from "./blogDraftListReducer";

const allReducers = combineReducers({
    blogList: blogListReducer,
    networkError: networkErrorReducer,
    blogDraftList: blogDraftListReducer,
});

export default allReducers;
