import BlogPostType from "../models/BlogPostType";


export const findPostWithId = (list: BlogPostType[], id: string) => {
    const isEqualToId = (post: BlogPostType):Boolean => {
        return post.id.toString() === id
    };
    return list.findIndex(isEqualToId);
};

export const sortByTimestamp = (a: BlogPostType, b: BlogPostType) => {
    return  b.timestamp - a.timestamp
};

export const sortByCreatedAt = (a: BlogPostType, b: BlogPostType) => {
    return  (b.created_at ? b.created_at : 0) - (a.created_at ? a.created_at : 0);
};

export const sortByUpdatedAt = (a: BlogPostType, b: BlogPostType) => {
    return  (b.updated_at ? b.updated_at : 0) - (a.updated_at ? a.updated_at : 0);
};

export const objToArray = (obj: any) => {
    let tempList: BlogPostType[] =[];
    Object.keys(obj).forEach( (key) => {
        let value = obj[key];
        value.id = key;
        tempList.push(value);
    });
    tempList.sort(sortByTimestamp);
    return tempList;
};

