import './BlogList.scss'
import React, {useEffect, useState} from 'react'
import {withFirebase} from '../../components/Firebase'
import BlogPostType from "../../models/BlogPostType";
import BlogPreview from "../../components/BlogPreview";
import {useDispatch} from "react-redux";
import {addData} from "../../actions";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {AppBar} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {objToArray, sortByCreatedAt, sortByTimestamp, sortByUpdatedAt} from "../../utils/utils";
import SetFeaturedBlog from "../../components/SetFeaturedBlog";
import StarIcon from '@material-ui/icons/Star';

const BlogList = (props: any) => {
    const {t} = useTranslation()
    const [post, setPost] = useState<BlogPostType[]>([]);
    const [draft, setDraft] =  useState<BlogPostType[]>([]);
    const [showSetFeatured, setShowSetFeatured] = useState(false);
    const dispatch = useDispatch();
    let history = useHistory();
    const [orderBy, setOrderBy] = useState(0);

    const reloadData = () => {
        props.firebase.getData(process.env.REACT_APP_BLOG_POST_URL).then( (response: any) => {
            const list = objToArray(response.val());
            dispatch(addData(list));
            setPost(list);
        });

        props.firebase.getData(process.env.REACT_APP_BLOG_DRAFT_URL).then( (response: any) => {
            if(response.val()) {
                const list = objToArray(response.val());
                dispatch(addData(list));
                setDraft(objToArray(response.val()));
            };
        })
    };

    useEffect(() => {
        reloadData();
        // eslint-disable-next-line
    }, []);

    const createBlog = () => {
        history.push('/createBlog');
    };

    const changeOrderBy = (event: any) => {
        switch (Number(event.target.value)) {
            case 0: {
                let tempList = post.sort(sortByTimestamp).slice();
                setOrderBy(0);
                setPost(tempList);
                tempList = draft.sort(sortByTimestamp).slice();
                setDraft(tempList);
                break;
            }

            case 1: {
                let tempList = post.sort(sortByCreatedAt).slice();
                setOrderBy(1);
                setPost(tempList);
                tempList = draft.sort(sortByCreatedAt).slice();
                setDraft(tempList);
                break;
            }

            case 2: {
                let tempList = post.sort(sortByUpdatedAt).slice();
                setOrderBy(2);
                setPost(tempList);
                tempList = draft.sort(sortByUpdatedAt).slice();
                setDraft(tempList);
                break;
            }
        }
        console.log(event.target.value);
    };

    const toolbar = (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6">
                    {t('blogList.blogList')}
                </Typography>
                <div className={"left-toolbar"}>
                    <IconButton edge="end" color="inherit" aria-label="menu" onClick={() => setShowSetFeatured(true)}>
                        <StarIcon />
                    </IconButton>
                    <IconButton edge="end" color="inherit" aria-label="menu" onClick={createBlog}>
                        <NoteAddIcon />
                    </IconButton>
                </div>
            </Toolbar>
        </AppBar>
    );

    const hideSetFeatured = () => {
        setShowSetFeatured(false);
    }


    return (
        <div className={"blog-list-container"}>
            {showSetFeatured ? <SetFeaturedBlog blogList={post} exit={hideSetFeatured}/> : null}
            {toolbar}
            <FormControl className={"order-by"}>
                <InputLabel htmlFor="age-native-simple">{t("utils.orderBy.orderBy")}</InputLabel>
                <Select
                    native
                    value={orderBy}
                    onChange={changeOrderBy}>
                    <option value={0}>{t('utils.orderBy.date')}</option>
                    <option value={1}>{t('utils.orderBy.created')}</option>
                    <option value={2}>{t('utils.orderBy.updated')}</option>
                </Select>
            </FormControl>
            <div className={"list"}>
                {draft?.map( (post: BlogPostType, index: number) => <BlogPreview blogPost={post} key={index} isDraft reloadData={reloadData}/>)}
                {post?.map( (post: BlogPostType, index: number) => <BlogPreview blogPost={post} key={index} reloadData={reloadData}/>)}
            </div>
        </div>
    )
}

export default withFirebase(BlogList);
