import {Reducer} from "redux";

const blogListReducer: Reducer = (state= [], action) => {
    switch (action.type) {
        case 'ADD_DATA':
            return state.concat(action.payload);
        default:
            return state;
    }
}

export default blogListReducer;
