import {Reducer} from "redux";

const networkErrorReducer: Reducer = (state= [], action) => {
    switch (action.type) {
        case 'LOG_ERROR':
            return action.payload;
        default:
            return false;
    }
}

export default networkErrorReducer;