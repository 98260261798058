import {Reducer} from "redux";

const blogDraftListReducer: Reducer = (state= [], action) => {
    switch (action.type) {
        case 'ADD_DRAFT_DATA':
            return action.payload;
        default:
            return state;
    }
}

export default blogDraftListReducer;
