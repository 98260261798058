import React from "react";
import './NavigationBar.scss'
import Avatar from '@material-ui/core/Avatar';
import Typography from "@material-ui/core/Typography";
import {Link } from "react-router-dom";
import {useMediaQuery} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";

const NavigationBar = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const navigationContent = (
        <div className={"content"}>
            <Link to={'/'}>
                <Avatar alt="Remy Sharp" src="/images/blog_avatar.jpg" />
                <Typography className={"title"} variant={"h4"}>Le Blog</Typography>
            </Link>
        </div>
    )

    const desktopNavigationBar = (
        <>
            <div className={"navigation-bar"}>
                {navigationContent}
            </div>
            <div className={"navigation-margin"}></div>
        </>
    );

    const mobileNavigationBar = (
        <div className={"navigation-bar mobile"}>
            {navigationContent}
        </div>
    );

    return isMobile ? mobileNavigationBar : desktopNavigationBar

};

export default NavigationBar