import React, { useEffect, useState} from "react";
import './SetFeaturedBlog.scss';
import BlogPostType from "../../models/BlogPostType";
import {FormControl} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import {withFirebase} from "../Firebase";
import {findPostWithId} from "../../utils/utils";
import {useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
type SetFeaturedBlogProps = {
    firebase?: any,
    blogList: BlogPostType[],
    exit: any,
}

const SetFeaturedBlog = (props: SetFeaturedBlogProps) => {
    const [featuredList, setFeaturedList] = useState<string[]>(["","","","",""]);
    // @ts-ignore
    const blogList: [BlogPostType] = useSelector(state => state.blogList);

    useEffect(() => {
        props.firebase.getData('blog/featured').then((result: any) => {
            setFeaturedList(result.val());
        })
    }, [props]);

    if (!blogList.length) {
        return null
    }

    const handleChange = (event:any) => {
        event.stopPropagation();
        const tempList = featuredList.slice();
        console.log( event.target.value);
        tempList[Number(event.target.name)] = blogList[event.target.value].id;
        setFeaturedList(tempList);
    };

    const submitFeatured = (event: any) => {
        event.stopPropagation();
        props.firebase.updateData(featuredList, 'blog/featured').then(() => {
            props.exit();
        })
    };

    const rowOfIndex = [];

    for (let i = 0; i < 5; i++) {
        rowOfIndex.push(i);
    }

    return (
        <div className={"background-modal"} onClick={props.exit}>
            <div className={"modal-container"}>
                <div className={"inner-content"}>
                    <Typography variant={"h6"}>Set Featured</Typography>
                    {
                        rowOfIndex.map((el: number) => (
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">#{el + 1}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name={el.toString()}
                                    value={findPostWithId(blogList, featuredList[el])}
                                    onChange={handleChange}
                                >
                                    {props.blogList.map((blog:BlogPostType, index: number) =>  <MenuItem value={index}>{blog.title}</MenuItem>)}
                                </Select>
                            </FormControl>
                        ))
                    }
                    <div className={"button-container"}>
                        <Button onClick={props.exit}>Cancel</Button>
                        <Button variant="contained" color="primary" onClick={submitFeatured}>Save</Button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default withFirebase(SetFeaturedBlog);
