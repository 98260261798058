import React, {Component} from 'react';
import './App.css';
import Home from "./pages/Home";
import { withFirebase } from './components/Firebase';
import { User } from "firebase";
import NavigationBar from "./components/NavigationBar";
import {
    Switch,
    Route,
} from 'react-router-dom';
import BlogPost from "./pages/BlogPost";
import CreateBlog from "./pages/CreateBlog";
import SignIn from "./pages/SignIn/SignIn";

type AppState = {
    authUser: any,
}

type AppProps = {
    firebase: any,
}

const authUser = (
    <Switch>
        <Route path="/blog/:id">
            {<BlogPost/>}
        </Route>
        <Route path="/updateBlog/:id">
            {<CreateBlog/>}
        </Route>
        <Route path="/draft/:id">
            {<CreateBlog/>}
        </Route>
        <Route path="/createBlog">
            {<CreateBlog/>}
        </Route>
        <Route path="/">
            <Home />
        </Route>
    </Switch>
);

const notAuthUser = (
    <SignIn/>
)

class App extends Component<AppProps, AppState> {
    constructor(props: any) {
        super(props)
        this.state = {
            authUser: null,
        };
    }

    listener: any;

    componentDidMount(): void {
        this.listener = this.props.firebase.auth.onAuthStateChanged((authUser: User ) => {
            authUser
                ? this.setState({ authUser })
                : this.setState({ authUser: null });
        });
    }

    componentWillUnmount() {
        this.listener();
    }

    render() {
        return (
            <>
                <NavigationBar />
                {this.state.authUser ? authUser : notAuthUser}

            </>
        );
    }

}

export default withFirebase(App);
