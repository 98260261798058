import { useParams, Redirect   } from "react-router-dom";

import React from "react";
import './BlogPost.scss'
import BlogPostType from "../../models/BlogPostType";
import {Typography} from "@material-ui/core";
import moment from "moment";
import 'moment/locale/fr.js'
import BlogPicture from "../../models/BlogPicture";

import CSS from 'csstype';

import {useSelector} from "react-redux";
import BlogPreview from "../../components/BlogPreview";
import {useTranslation} from "react-i18next";
import {findPostWithId} from "../../utils/utils";

const BlogPost = (props: any) => {
    let { id } = useParams();
    const {t} = useTranslation();

    window.scrollTo(0, 0);

    // @ts-ignore
    const blogList: [BlogPostType] = useSelector(state => state.blogList);
    if(blogList === null) {
        return <Redirect to='' />
    }

    let blogIndex = 0;
    let blogPost = null;

    if(props.blog) {
        blogPost = props.blog;

    } else {
        blogIndex = findPostWithId(blogList, id!);
        if(blogIndex === -1) {
            return <Redirect to='/error/' />
        }
        blogPost = blogList[blogIndex];
    }



    moment.locale('fr');

    const morePublications = (
        <>
            <Typography className={"more-publications"} variant={"h6"}>{t('morePublications')}</Typography>
            <div className={"preview-container"}>
                {blogIndex > 0?<BlogPreview blogPost={blogList[blogIndex - 1]} isEndPost/> : null}
                {blogIndex < blogList.length - 1 ?  <BlogPreview blogPost={blogList[blogIndex + 1]} isEndPost/>: null}
            </div>
        </>
    )


    const backgroundHeaderStyle: CSS.Properties = {
        backgroundImage: "url("+ blogPost.coverPicture.src + ")"
    }

    return (
        <div className={"entire-blog-container"}>
            <div className={"blog-title blog-container"}>
                <Typography variant={"h2"}>{blogPost.title}</Typography>
                <Typography variant={"subtitle2"} className={"timestamp"}>{moment(blogPost.timestamp).format("Do MMMM YYYY")} {blogPost.location ?  " - " + blogPost.location : ""}</Typography>
            </div>
            <div className={"blog-photo-cover"} style={backgroundHeaderStyle}>

            </div>
            <Typography variant={"caption"} className={"cover-caption"}>{blogPost.coverPicture.alt}</Typography>
            <div className={"blog-container"}>
                <div className={"text-container"}>
                    {
                        blogPost.content?.map((blogContent: any, index: number) => {
                            switch (blogContent.type) {
                                case "text":
                                    return <Typography key={index} variant={"body1"} className={"content"} >{blogContent.content}</Typography>
                                default:
                                    return null
                            }
                        })
                    }
                </div>

                <div className={"img-container"}>
                    {
                        blogPost.pictures?.map((picture: BlogPicture, index: number) => (
                            <>
                                <img src={picture.src} alt={picture.alt} key={index} />
                            </>
                        ))
                    }
                </div>

                <div className={"navigate-blog"}>
                    {(blogIndex > 0 || blogIndex < blogList.length - 1)? morePublications: null}
                </div>
            </div>
        </div>
    )
}

export default BlogPost;
