import React from "react";
import './Home.scss'
import BlogList from "../BlogList";


const Home = () => {
    return <BlogList />
};

export default Home;
