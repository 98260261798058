import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './i18n';
import "firebase/auth";
import Firebase, { FirebaseContext } from './components/Firebase';
import {BrowserRouter} from "react-router-dom";

import {compose, createStore} from "redux";
import allReducers from "./reducers";
import { Provider } from 'react-redux';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    allReducers,
    composeEnhancers()
);

// @ts-ignore
ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <FirebaseContext.Provider value={new Firebase()}>
              <Suspense fallback={"loading"}>
                  <BrowserRouter>
                      <App />
                  </BrowserRouter>
              </Suspense>
          </FirebaseContext.Provider>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
