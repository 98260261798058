import app from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENTID,
}

class Firebase {
    constructor() {
        app.initializeApp(config);

        this.database = app.database();
        this.auth = app.auth();
        this.storage = app.storage();
    }

    database: any;
    auth: any;
    storage: any;

    doSignInWithGoogle = () => {
        let provider = new app.auth.GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');
        return this.auth.signInWithPopup(provider);
    };

    doSignOut = () => this.auth.signOut();

    getData = (databasePath: string) => {
        return this.database.ref(databasePath).once('value');
    };

    removeData = (path: string) => {
        return this.database.ref(path).remove();
    };

    updateData = (obj: any, path: string) => {
        return this.database.ref(path).update(obj)
    }

    writeData = (obj: any, databasePath: string) => {
        return this.database.ref(databasePath).push(obj);
    }

    uploadFile = (file: File, path: string) => {
        let storageRef = this.storage.ref(path + file.name);
        return storageRef.put(file)
    }

    uploadFileAndGetUrl = (file: File, path: string) => {
        return new Promise((resolve) => {
            this.uploadFile(file, path).then((snapshot: any) => {
                snapshot.ref.getDownloadURL().then( (result: any) => {
                    resolve(result);
                });
            })
        })
    }

}

export default Firebase;
