import './SignIn.scss'
import React from "react";
import {withFirebase} from '../../components/Firebase'
import Button from "@material-ui/core/Button";
import YouTubeIcon from '@material-ui/icons/YouTube';

const SignIn = (props: any) => {

    const signIn = () => {
        props.firebase.doSignInWithGoogle();
    }

    return (
        <div className={"sign-in-container"}>
            <Button variant="contained" onClick={signIn} startIcon={<YouTubeIcon />}>Log with google</Button>
        </div>)
};

export default withFirebase(SignIn);
